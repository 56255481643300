<template>
  <v-app id="r1-admin" class="gp-full-height">
    <v-navigation-drawer
      v-model="drawer"
      v-bind:width="325"
      app
      style="height: 100%"
    >
      <v-row>
        <v-col cols="12">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title mb-2">
                  <span class="font-weight-bold">R1.Администратор</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <base-avatar size=24 class="mr-2"/>{{ userName }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <div v-for="navLink in navLinks" :key="navLink.text">
              <div v-if="!navLink.subLinks">
                <v-list-item :to="navLink.route" link>
                  <v-list-item-icon>
                    <v-icon>{{ navLink.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ navLink.text }}</v-list-item-title>
                </v-list-item>
              </div>
              <div v-if="!!navLink.subLinks">
                <v-list-group no-action>
                  <v-list-item slot="activator">
                    <v-list-item-icon>
                      <v-icon>{{ navLink.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ navLink.text }}</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-for="sub in navLink.subLinks"
                    :key="sub.text"
                    :to="sub.route"
                    link
                  >
                    <v-list-item-icon>
                      <v-icon>{{ sub.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ sub.text }}</v-list-item-title>
                  </v-list-item>
                </v-list-group>
              </div>
            </div>
          </v-list>
        </v-col>
      </v-row>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logout">Выход</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-breadcrumbs :items="items_bc" class="pl-2" large></v-breadcrumbs>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
      <div class="d-flex d-md-none" style="height: 56px"></div>
    </v-main>
  </v-app>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapActions } from 'vuex';
import BaseAvatar from '@/components/Base/Avatar/BaseAvatar.vue';
import routes from '@/plugins/routes';

export default {
  components: { BaseAvatar },
  props: {
    source: String,
  },
  name: 'FullHeight',
  data() {
    return {
      drawer: null,
      dialog: false,
      dialogUpdate: false,
      refreshing: false,
      registration: null,
      snackWithButtons: false,

      navLinks: [
        {
          icon: 'mdi-view-dashboard',
          text: 'Дашборд',
          route: '/dashboard',
          subLinks: false,
        },
        {
          icon: 'mdi-domain',
          text: 'Объекты',
          route: '/objects',
          subLinks: [
            {
              icon: 'mdi-city',
              text: 'Города',
              route: '/objects/cities',
            },
            {
              icon: 'mdi-office-building',
              text: 'Дома',
              route: '/objects/houses',
            },
            {
              icon: 'mdi-office-building-marker',
              text: 'Помещения',
              route: '/objects/premises',
            },
            {
              icon: 'mdi-dots-square',
              text: 'Точки учета',
              route: '/objects/metering-points',
            },
          ],
        },
        {
          icon: 'mdi-account-multiple',
          text: 'Клиенты',
          route: '/сlients',
          subLinks: [
            {
              icon: 'mdi-account',
              text: 'Контрагенты',
              route: '/clients/counterparties',
            },
            {
              icon: 'mdi-card-account-details',
              text: 'Лицевые счета',
              route: '/clients/accounts',
            },
          ],
        },
        {
          icon: 'mdi-devices',
          text: 'Устройства',
          route: '/devices',
          subLinks: [
            {
              icon: 'mdi-counter',
              text: 'Контроллеры',
              route: '/devices/controllers',
            },
            {
              icon: 'mdi-meter-electric',
              text: 'Устройства (ПУ)',
              route: '/devices/devices-data',
            },
          ],
        },
        {
          icon: 'mdi-cash-clock',
          text: 'Тарификация',
          route: '/billing',
          subLinks: [
            {
              icon: 'mdi-calendar-clock',
              text: 'Расписание',
              route: '/billing/schedule',
            },
            {
              icon: 'mdi-numeric-7-box-multiple',
              text: 'Тарифы',
              route: '/billing/tariffs',
            },
          ],
        },
        {
          icon: 'mdi-list-box-outline',
          text: 'СКУД',
          route: '/acs-directories',
          subLinks: [
            {
              icon: 'mdi-office-building-marker',
              text: 'Списки доступа',
              route: '/acs-directories/acs-access-lists',
            },
            {
              icon: 'mdi-key',
              text: 'Ключи',
              route: '/acs-directories/acs-keys',
            },
          ],
        },
        {
          icon: 'mdi-forum-outline',
          text: 'Заявки',
          route: '/tickets',
          subLinks: [
            {
              icon: 'mdi-cog',
              text: 'Действия',
              route: '/tickets/actions',
            },
            {
              icon: 'mdi-account-multiple-outline',
              text: 'Исполнители',
              route: '/tickets/agents',
            },
            {
              icon: 'mdi-account-group-outline',
              text: 'Группы',
              route: '/tickets/groups',
            },
            {
              icon: 'mdi-vector-arrange-above',
              text: 'Типы объектов',
              route: '/tickets/objectsTypes',
            },
            {
              icon: 'mdi-alert-box-outline',
              text: 'Приоритеты',
              route: '/tickets/priorities',
            },
            {
              icon: 'mdi-form-select',
              text: 'Статусы',
              route: '/tickets/states',
            },
            {
              icon: 'mdi-checkerboard',
              text: 'Статус - действия',
              route: '/tickets/mapStates',
            },
          ],
        },
        {
          icon: 'mdi-list-box',
          text: 'Справочники',
          route: '/directories',
          subLinks: [
            {
              icon: 'mdi-account-multiple',
              text: 'Группы',
              route: '/directories/groups',
            },
            {
              icon: 'mdi-numeric',
              text: 'Единицы измерения',
              route: '/directories/units',
            },
            {
              icon: 'mdi-format-list-text',
              text: 'Типы данных учета',
              route: '/directories/meters-data-types',
            },
            {
              icon: 'mdi-ab-testing',
              text: 'Драйвер - тип данных',
              route: '/directories/meters-data-map',
            },
            {
              icon: 'mdi-ab-testing',
              text: 'Виды ресурсов',
              route: '/directories/resource-types',
            },
            {
              icon: 'mdi-map-marker-question',
              text: 'Типы помещений',
              route: '/directories/premises-types',
            },
            {
              icon: 'mdi-format-list-bulleted-square',
              text: 'Модели устройств',
              route: '/directories/devices-models',
            },
          ],
        },
        {
          icon: 'mdi-math-log',
          text: 'Журналы',
          route: '/logs',
          subLinks: [
            {
              icon: 'mdi-speedometer',
              text: 'Показания приборов',
              route: '/logs/meter-readings',
            },
            {
              icon: 'mdi-water-thermometer',
              text: 'Потребление ресурсов',
              route: '/logs/consumption',
            },
          ],
        },
        {
          icon: 'mdi-chart-timeline-variant-shimmer',
          text: 'Отчеты',
          route: '/reports',
          subLinks: [
            {
              icon: 'mdi-chart-line',
              text: 'Отчет 1',
              route: '/reports/report-one',
            },
          ],
        },
        {
          icon: 'mdi-cogs',
          text: 'Настройки',
          route: '/settings',
          subLinks: [
            {
              icon: 'mdi-account',
              text: 'Профиль пользователя',
              route: '/settings/user',
            },
            {
              icon: 'mdi-cog',
              text: 'Интерфейс',
              route: '/settings/preferences',
            },
            {
              icon: 'mdi-tune',
              text: 'Система',
              route: '/settings/app-config',
            },
            {
              icon: 'mdi-multimedia',
              text: 'Медиатека',
              route: '/settings/media-library',
            },
          ],
        },
      ],

      items_bc: [],
    };
  },
  created() {
    document.addEventListener('serviceWorkerUpdateEvent', this.showRefreshUI, {
      once: true,
    });

    // document.addEventListener('serviceWorkerUpdateEvent', this.showRefreshUI, {})
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        // eslint-disable-next-line no-console
        console.log('Application update.');
        window.location.reload();
      });
    }
  },

  mounted() {
    this.connectSocket({ url: routes.conf().wsURL });
    this.getUserInfo();
    this.items_bc = this.$route.meta.breadcrumb;
    this.setViewHeight();
    const debouncedSetHeight = debounce(this.setViewHeight, 50);
    window.addEventListener('resize', debouncedSetHeight);
    this.$once('destroyed', () => {
      window.removeEventListener('resize', debouncedSetHeight);
    });
  },
  watch: {
    $route() {
      this.items_bc = this.$route.meta.breadcrumb;
    },
  },
  methods: {
    ...mapActions('websocketStore', ['disconnectSocket', 'connectSocket']),
    ...mapActions('userSettings', ['getUserInfo']),
    ...mapActions('preferencesStore', ['getGlobalPreferences']),
    showRefreshUI(e) {
      // eslint-disable-next-line no-console
      console.log(e);
      this.registration = e.detail;
      this.snackWithButtons = true;
    },

    logout() {
      this.$store.commit('authStore/loggedOut');
      this.$router.push({ name: 'Login' });
      this.disconnectSocket();
    },
    setViewHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
  computed: {
    userName() {
      return this.$store.state.userSettings.shortName || 'Вы не авторизованы';
    },
  },
};
</script>

<style scoped>
/**
  Overwrite vuetify's default v-application--wrap min-height: 100vh;
  Due to the many problems with vh on mobile devices.
 */
.gp-full-height ::v-deep .v-application--wrap {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

::v-deep .v-list-group .v-list-item {
  padding-left: 8px;
}
</style>
