// eslint-disable-next-line import/no-cycle
import req from '@/plugins/requests';

const mediaLibraryStore = {
  namespaced: true,
  state: {
    mediaFilesList: [],
    mediaCategoriesList: [],
  },
  getters: {
    mediaFilesList: (state) => {
      if (!state.mediaFilesList) {
        return [];
      }
      return state.mediaFilesList;
    },
    mediaCategoriesList: (state) => state.mediaCategoriesList,
  },
  mutations: {
    SET_MEDIA_FILES(state, payload) {
      state.mediaFilesList = payload.data;
    },
    SET_CATEGORIES(state, payload) {
      state.mediaCategoriesList = payload.data;
    },
  },
  actions: {
    async getMediaFiles(context, payload) {
      const { data } = await req.post('mediaFiles', { category: payload.category });
      context.commit('SET_MEDIA_FILES', data);
      return data;
    },
    async getMediaCategories(context) {
      const { data } = await req.post('mediaCategories');
      context.commit('SET_CATEGORIES', data);
      return data;
    },
    async uploadMediaFiles(context, payload) {
      const data = await req.upload('uploadMediaFiles', payload);
      return data;
    },
  },
};

export default mediaLibraryStore;
