/* eslint-disable import/no-cycle */
import apiCodes from '@/constants/apiCodes';
import sortingMap from '@/constants/sortingMap';
import req from '@/plugins/requests';
import { PROD } from '@/plugins/routes';
import storage from '@/plugins/storage';

const createAvatarUrl = (path) => {
  const avatarTimestamp = Date.now();
  const avatarPath = `${path}?timestamp=${avatarTimestamp}`;
  return new URL(avatarPath, PROD.apiURL).href;
};

const userSettings = {
  namespaced: true,
  state: {
    activeSessionsList: [],
    userData: {},
    total: 0,
    fullName: '',
    shortName: '',
    avatarUrl: '',
    itemsPerPage: '',
    page: 1,
  },
  getters: {
    defaultPage: (state) => state.page,
    sessionsList: (state) => {
      if (!state.activeSessionsList) {
        return [];
      }
      return state.activeSessionsList;
    },
    getAvatarUrl: (state) => state.avatarUrl,
    totalSessions: (state) => state.total,
    itemsPerPage: (state) => state.itemsPerPage || storage.GET('r1_user_settings').itemsPerPage,
    userData: (state) => state.userData,
    defaultTableOptions: (state) => (tableName) => ({
      itemsPerPage: state.itemsPerPage || storage.GET('r1_user_settings').itemsPerPage,
      page: state.page,
      ...sortingMap()[tableName].sortSettings,
    }),
  },
  mutations: {
    SET_SESSIONS(state, payload) {
      if (payload.data) {
        state.activeSessionsList = payload.data.map((session) => {
          const timeCreate = new Date(
            // eslint-disable-next-line radix
            parseInt(session.create_time) * 1000,
          ).toLocaleString('Ru-ru');
          const timeUpdate = new Date(
            // eslint-disable-next-line radix
            parseInt(session.update_time) * 1000,
          ).toLocaleString('Ru-ru');

          return { ...session, timeCreate, timeUpdate };
        });
      }
      state.total = payload.total;
    },
    SET_USER_DATA(state, payload) {
      state.userData = payload.userData;
    },
    SET_USER_PREFERENCES(state, payload) {
      state.itemsPerPage = payload.itemsPerPage;
      storage.SET('r1_user_settings', { itemsPerPage: payload.itemsPerPage });
    },
    SET_USER_INFO(state, payload) {
      state.shortName = payload.short_name;
      state.fullName = payload.full_name;

      if (payload.avatar !== '') {
        state.avatarUrl = createAvatarUrl(payload.avatar);
      }
    },
  },
  actions: {
    async getSessionsList(context, payload) {
      const response = await req.post('userSessions', payload);
      context.commit('SET_SESSIONS', response.data);
    },
    async getUserData(context) {
      const response = await req.post('userSettings');
      context.commit('SET_USER_DATA', { userData: response.data });
      return response;
    },
    async deleteSession(context, payload) {
      await req.post('userSessionDelete', { id: payload });
    },
    async updatePassword(context, payload) {
      await req.post('userPasswordUpdate', payload);
    },
    async getUserInfo(context) {
      const { data } = await req.post('userInfo');
      context.commit('SET_USER_INFO', data);
      context.commit('SET_USER_PREFERENCES', { itemsPerPage: data.rows_per_page });
    },
    async setUserPreferences(context, payload) {
      const data = await req.post('userPreferencesSave', { obj: { rows_per_page: payload } });
      if (data.code === apiCodes.ok) {
        context.commit('SET_USER_PREFERENCES', { itemsPerPage: payload });
      }
    },
    async updateAvatar(context, payload) {
      const data = await req.upload('userAvatarSave', payload);
      return data;
    },
  },
};

export default userSettings;
