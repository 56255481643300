/* eslint-disable import/no-cycle */
import store from '@/store';
import router from '@/router';
import apiCodes from '@/constants/apiCodes';
import httpApi from './httpApi';

const requestReport = (responseResult) => {
  if (!responseResult) {
    return {
      msg: 'Неизвестная ошибка.',
      type: 'error',
    };
  }

  const {
    status, req, code, txt,
  } = responseResult;

  let msg = '';
  let type = '';

  switch (status) {
    case 'success':
      switch (req) {
        case 'create':
          msg = 'Запись добавлена.';
          type = 'success';
          break;
        case 'update':
          msg = 'Запись изменена.';
          type = 'success';
          break;
        case 'delete':
          msg = 'Запись удалена.';
          type = 'success';
          break;
        default:
          break;
      }
      break;
    case 'error':
      switch (req) {
        case 'create':
          msg = `Ошибка добавления записи: ${code}, ${txt}`;
          type = 'error';
          break;
        case 'update':
          msg = `Ошибка изменения записи: ${code}, ${txt}`;
          type = 'error';
          break;
        case 'delete':
          msg = `Ошибка удаления записи: ${code}, ${txt}`;
          type = 'error';
          break;
        case 'login':
          msg = 'Ошибка входа, проверьте логин и пароль.';
          type = 'error';
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
  return { msg, type };
};

let preventMultipleRouting = false;

const req = async (reqName, method, param) => {
  try {
    const headers = store.getters['authStore/headerAuth'];
    const response = await httpApi.post(reqName, { headers, param });
    const { data } = response;

    if (data.code === apiCodes.wrongToken || data.code === apiCodes.wrongRefreshToken) {
      if (!preventMultipleRouting) {
        store.commit('authStore/loggedOut');
        store.dispatch('websocketStore/disconnectSocket');
        router.push({ name: 'Login' });
        preventMultipleRouting = true;
        setTimeout(() => {
          preventMultipleRouting = false;
        }, 1000);
        throw new Error(`${data.code}`, {
          cause: {
            status: 'error',
            req: 'login',
          },
        });
      }
    }

    // need to update token
    if (data.code === apiCodes.tokenUpdate) {
      store.commit('authStore/UpdateToken', data.token);
      const refresh = await req(reqName, method, param);
      return refresh;
    }

    // show auth error (wrong login / pass)
    if (data.code === apiCodes.postgreError && reqName === 'auth') {
      throw new Error(`${data.code}`, {
        cause: {
          status: 'error',
          req: 'login',
          txt: data.msg,
          code: data.code,
        },
      });
    }

    // wrong data response format
    if (data.code === apiCodes.wrongFormat) {
      throw new Error(`${data.code}`, {
        cause: {
          status: 'error',
          req: 'create',
          txt: data.result.txt,
          code: data.code,
        },
      });
    }

    // result message of any action
    if (data.result && data.result.req !== 'list') {
      if (data.result.req !== 'item') {
        store.commit('notificationMessages', {
          ...requestReport({
            ...data.result,
          }),
        });
      }
      return data;
    }
    return response;
  } catch (error) {
    store.commit('notificationMessages', {
      ...requestReport({
        ...error.cause,
      }),
    });
    return { code: apiCodes.ok };
  }
};

const reqUpload = async (reqName, formData) => {
  try {
    const headers = store.getters['authStore/headerAuth'];
    const { data } = await httpApi.upload(reqName, formData, headers);

    if (data.code === apiCodes.tokenUpdate) {
      store.commit('authStore/UpdateToken', data.token);
      const refresh = await reqUpload(reqName, formData);
      return refresh;
    }

    if (data.code === apiCodes.wrongToken || data.code === apiCodes.wrongRefreshToken) {
      store.commit('authStore/loggedOut');
      store.dispatch('websocketStore/disconnectSocket');
      router.push({ name: 'Login' });
    }

    if (data.code === apiCodes.ok) {
      store.commit(
        'notificationMessages',
        {
          msg: 'Файл(ы) загружен(ы)',
          type: 'success',
        },
      );
    }

    return data;
  } catch (error) {
    store.commit('notificationMessages', {
      ...requestReport(error.cause),
    });
    return error;
  }
};

export default {
  post: (reqName, param) => req(reqName, 'post', param),
  upload: (reqName, formData) => reqUpload(reqName, formData),
  filterData: (filterName) => req('filterData', 'post', { name: filterName }),
  getItem: (reqName, itemId) => req(reqName, 'post', { id: itemId }),
};
