/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';
import apiCodes from '@/constants/apiCodes';

const preferencesStore = {
  namespaced: true,

  state: {
    // ToDo: эти данные заменят файл defaultOptions.js
    ALL_ITEMS_ID: 0,
    NEW_CITY_ID: 0,
    NEW_HOUSE_ID: 0,
    DEFAULT_PAGE_NUMBER: 1,
    NEW_PREMISE_ID: 0,
    NEW_METERING_POINT_ID: 0,
    NEW_ACSKEY_ID: 0,
    NEW_ACCOUNT_ID: 0,
    NEW_ACCOUNT_MASTER_ACCOUNT_ID: 0,
    NEW_PERSON_ACCOUNT_ID: 0,
    NEW_CONTROLLER_ID: 0,
    NEW_DEVICE_OBJECT_ID: 0,
    SUPER_ADMIN_ID: 1,
    newAccountId: 0,
    newAcсountMasterAccountId: 0,
    newAccountFormId: 2,
    newAccountGroupId: 2000,
    allItemsValue: 'Все',
    nonEditedItemId: -1,
    NON_EDITED_ITEM_ID: -1,
    nonEditedItemId2: -100,
    superAdminId: 1,
    order: 1000,

    mediaLibrary: {
      skeletonCount: 10,
      defaultFilters: {
        category: 'images',
      },
      maxFileSize: 2500000,
      placeHolderDefaultImage: '/img/placeHolder.jpg',
    },

    avatarFileSize: 2500000,
    journalRowsCount: 5,
    journalRowsOptions: [
      { text: 5, value: 5 },
      { text: 10, value: 10 },
      { text: 15, value: 15 },
      { text: 20, value: 20 },
    ],
    itemsPerPageDefault: 10,
    itemsPerPageOptions: [
      { text: 5, value: 5 },
      { text: 10, value: 10 },
      { text: 15, value: 15 },
      { text: 25, value: 25 },
      { text: 50, value: 50 },
      { text: 'Все', value: -1 },
    ],
  },
  getters: {
    defaultPage: (state) => state.DEFAULT_PAGE_NUMBER,
    itemsPerPageDefault: (state) => state.itemsPerPageDefault,
    itemsPerPageOptions: (state) => state.itemsPerPageOptions,
    journalRowsCount: (state) => state.journalRowsCount,
    journalRowsOptions: (state) => state.journalRowsOptions,
  },
  mutations: {
    SET_ITEM_PER_PAGE(state, payload) {
      state.itemsPerPageDefault = payload;
    },
    SET_JOURNAL_ROWS_COUNT(state, payload) {
      state.journalRowsCount = payload;
    },
  },
  actions: {
    async getGlobalPreferences(context) {
      const { data } = await req.post('appInterface');
      if (data.code === apiCodes.ok) {
        context.commit('SET_ITEM_PER_PAGE', data.data.rows_per_page);
        context.commit('SET_JOURNAL_ROWS_COUNT', data.data.journal_rows_short);
      }
    },
    async setGlobalPreferences(context, payload) {
      const data = await req.post('appInterfaceSave', { obj: payload });
      if (data.code === apiCodes.ok) {
        context.commit('SET_JOURNAL_ROWS_COUNT', payload.journal_rows_short);
        context.commit('SET_ITEM_PER_PAGE', payload.rows_per_page);
      }
    },
  },
};

export default preferencesStore;
