<template>
  <component :is="layout"></component>
</template>

<script>
import { mapState } from 'vuex';
import LayoutLogin from './views/LayoutLogin.vue';
import LayoutMain from './views/LayoutMain.vue';

export default {
  name: 'App',
  components: {
    LayoutLogin,
    LayoutMain,
  },
  computed: {
    layout() {
      return `Layout${this.$route.meta.layout || 'Login'}`;
    },
    ...mapState(['error', 'notificationMessages']),
  },
  watch: {
    error(val) {
      this.$toast.open({ message: val.msg, type: val.type });
    },
    notificationMessages(val) {
      this.$toast.open({ message: val.msg, type: val.type });
    },
  },
};
</script>
