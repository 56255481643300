import Vue from 'vue';
import VueMask from 'v-mask';
import VueToast from 'vue-toast-notification';
import VueRouter from 'vue-router';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import './registerServiceWorker';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import 'vue-toast-notification/dist/theme-default.css';
import './assets/app.css';

Vue.config.productionTip = false;
Vue.prototype.ws = null;

Vue.use(VueRouter);
Vue.use(VueMask);
Vue.use(VueToast, { duration: 5000 });

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
