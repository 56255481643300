const sortingMap = () => ({
  r1_houses_tableOptions: {
    fieldSettings: {
      fields: ['c_name', 'geoCoordinates'],
      c_name: ['c_name', 'h_addr'],
      geoCoordinates: ['h_lat', 'h_lon'],
    },
    sortSettings: {
      sortBy: ['c_name'],
      sortDesc: [false],
    },
  },
  r1_cities_tableOptions: {
    sortSettings: {
      sortBy: ['c_name'],
      sortDesc: [false],
    },
  },
  r1_premises_tableOptions: {
    fieldSettings: {
      fields: ['c_name', 'h_addr'],
      c_name: ['c_name', 'h_addr', 'p_addr'],
      h_addr: ['h_addr', 'p_addr'],
    },
    sortSettings: {
      sortBy: ['c_name'],
      sortDesc: [false],
    },
  },
  r1_meteringPoints_tableOptions: {
    fieldSettings: {
      fields: ['c_name', 'h_addr', 'p_addr', 'mrt_name'],
      c_name: ['c_name', 'h_addr', 'p_addr', 'mrt_name', 'mp_code'],
      h_addr: ['h_addr', 'p_addr', 'mrt_name', 'mp_code'],
      p_addr: ['p_addr', 'mrt_name', 'mp_code'],
      mrt_name: ['mrt_name', 'mp_code'],
    },
    sortSettings: {
      sortBy: ['c_name'],
      sortDesc: [false],
    },
  },
  r1_skudKeys_tableOptions: {
    sortSettings: {
      sortBy: ['person_full'],
      sortDesc: [false],
    },
  },
  r1_accounts_tableOptions: {
    sortSettings: {
      sortBy: ['cl_full'],
      sortDesc: [false],
    },
  },
  r1_persons_tableOptions: {
    fieldSettings: {
      fields: ['lname'],
      lname: ['lname', 'fname', 'mname'],
    },
    sortSettings: {
      sortBy: ['lname'],
      sortDesc: [false],
    },
  },
  r1_controllers_tableOptions: {
    fieldSettings: {
      fields: ['city_name', 'h_addr', 'premiseAddress'],
      city_name: ['city_name', 'h_addr', 'p_addr_num', 'p_addr_suffix', 'c_name'],
      h_addr: ['h_addr', 'p_addr_num', 'p_addr_suffix', 'c_name'],
      premiseAddress: ['p_addr_num', 'p_addr_suffix', 'c_name'],
    },
    sortSettings: {
      sortBy: ['city_name'],
      sortDesc: [false],
    },
  },
  r1_devices_tableOptions: {
    fieldSettings: {
      fields: ['premise_addr'],
      premise_addr: ['premise_addr', 'device_addr'],
    },
    sortSettings: {
      sortBy: ['premise_addr'],
      sortDesc: [true],
    },
  },
  r1_groupsPermissions_tableOptions: {
    sortSettings: {
      sortBy: ['pg_name'],
      sortDesc: [false],
    },
  },
  r1_resourceTypes_tableOptions: {
    sortSettings: {
      sortBy: ['name'],
      sortDesc: [false],
    },
  },
  r1_metersDataMap_tableOptions: {
    sortSettings: {
      sortBy: ['driver_name'],
      sortDesc: [false],
    },
  },
});

export default sortingMap;
