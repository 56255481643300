/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const housesStore = {
  namespaced: true,
  state: {
    houses: [],
    totalHouses: 0,
  },

  getters: {
    totalHouses: (state) => state.totalHouses,
    housesList: (state) => {
      if (!state.houses) {
        return [];
      }
      return state.houses.map((house) => {
        // eslint-disable-next-line no-param-reassign
        house.geoCoordinates = `${house.h_lat}, ${house.h_lon}`;
        // eslint-disable-next-line no-param-reassign
        house.addrName = `"${house.h_name}", ${house.h_addr}`;
        return house;
      });
    },
  },
  mutations: {
    ADD_HOUSE(state, payload) {
      state.houses.push(payload);
    },
    SET_HOUSES(state, payload) {
      state.houses = payload.data;
      state.totalHouses = payload.total;
    },
    UPDATE_HOUSE(state, payload) {
      state.houses = state.houses.map((house) => {
        if (house.h_id === payload.h_id) {
          // eslint-disable-next-line no-param-reassign
          house = payload;
          return house;
        }
        return house;
      });
    },
    DELETE_HOUSE(state, payload) {
      state.houses = state.houses.filter((house) => house.h_id !== payload);
    },
  },
  actions: {
    async addNewHouse(context, payload) {
      const response = await req.post('housesSave', { obj: { ...payload } });
      return response;
    },
    async updateHouse(context, payload) {
      const response = await req.post('housesSave', { obj: { ...payload } });
      return response;
    },
    removeHouse(context, payload) {
      context.commit('DELETE_HOUSE', payload);
    },
    async getHouses(context, payload) {
      const { data } = await req.post('houses', payload);
      context.commit('SET_HOUSES', data);
      return data;
    },
  },
};

export default housesStore;
