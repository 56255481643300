/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const skudKeysStore = {
  namespaced: true,
  state: {
    skudKeys: [],
    skudKeysCount: 0,
  },
  mutations: {
    SET_KEYS: (state, payload) => {
      state.skudKeys = payload.data;
      state.skudKeysCount = payload.total;
    },
  },
  actions: {
    async getSkudKeys(context, payload) {
      const response = await req.post('skudKeys', payload);
      context.commit('SET_KEYS', response.data);
      return response.data;
    },
    async updateSkudKey(context, payload) {
      const response = await req.post('skudKeysSave', { obj: { ...payload } });
      return response;
    },
  },
};

export default skudKeysStore;
